import $ from "jquery";
import jQuery from 'jquery';
window.$ = $;
import "slick-carousel";
// import "selectric";
import Sortable from 'sortable'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
// import "cycle2";

// global.jQuery = require('jquery');
// var selectric = require('selectric');
import selectric from "./vendor/jquery.selectric.js";

// console.log($)

import '@img/interface/404-image.jpg';
import '@img/interface/912903906-2048x2048.jpg';
import '@img/interface/BASF_EMS_wh.png';
import '@img/interface/BASF-solid-white-logo.svg';
import '@img/interface/dotted-border.png';
import '@img/interface/eu-product-cta-img.jpg';
import '@img/interface/formulation-feed-placement.jpg';
import '@img/interface/high-contrast.svg';
import '@img/interface/id514380901.png';
import '@img/interface/istockphoto-537397432-2048x2048.jpg';
import '@img/interface/istockphoto-537397432-2048x2048.png';
import '@img/interface/istockphoto-810056512-2048x2048.jpg';
import '@img/interface/landing-page-bg.jpg';
import '@img/interface/logo_black.png';
import '@img/interface/logo_black.svg';
import '@img/interface/logo_carecreations-white.svg';
import '@img/interface/logo.svg';
import '@img/interface/pdf.gif';
import '@img/interface/pdf.png';
import '@img/interface/play.png';
import '@img/interface/play.svg';
import '@img/interface/product-feed-placement-large.jpg';
import '@img/interface/product-feed-placement.jpg';
import '@img/interface/video-imgs/video-bg-testing.jpg';

import './vendor';
import './gdpr-cookies';
import { getCookie, showGdprPopup } from './gdpr-cookies.js';

$(document).ready(function(){
    console.log('loaded')
    console.log(window.location.href)
    console.log(selectric)
    console.log(selectric)
    console.log(window.location.href.indexOf('?login=true'))
    if (window.location.href.indexOf('?login=true') > -1) {
        $.ajax({
            type: "GET",
            url: '/api/logged-in-user.json',
            dataType: "json",
            success: function (data) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'customer_id': data.id,
                    'customer_username': data.username,
                    'customer_email': data.email,
                    'event': 'customerLogInData',
                });
            }
        });
    }

    $('.js-print').click(function(){
        javascript:window.print()
    });

    $('.home-slider').slick({
        dots: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        infinite: true,
        arrows: true,
        speed: 1000,
        prevArrow: $('.slick-btn__prev'),
        nextArrow: $('.slick-btn__next'),
        autoplay: true,
        autoplaySpeed: 8000,
    });

    // Update the landing page theme color CSS variables
    if ($('body').hasClass('landingPage')) {
        var root = document.querySelector(':root');
        if ($('body').hasClass('green')) {
            root.style.setProperty('--landing-page-color', '#65AC1E');
            root.style.setProperty('--landing-page-color-rgb', '101,172,30');
            root.style.setProperty('--landing-page-lighten', '#BADA9A');
        }
        if ($('body').hasClass('blue')) {
            root.style.setProperty('--landing-page-color', '#21a0d2');
            root.style.setProperty('--landing-page-color-rgb', '33,160,210');
            root.style.setProperty('--landing-page-lighten', '#CCDBEA');
        }
        if ($('body').hasClass('orange')) {
            root.style.setProperty('--landing-page-color', '#F39500');
            root.style.setProperty('--landing-page-color-rgb', '243,149,0');
            root.style.setProperty('--landing-page-lighten', '#FACF8C');
        }
        if ($('body').hasClass('red')) {
            root.style.setProperty('--landing-page-color', '#C50022');
            root.style.setProperty('--landing-page-color-rgb', '197,0,34');
            root.style.setProperty('--landing-page-lighten', '#E78F8E');
        }
    }

    $('input:checkbox').click(function () {
        var groupId = $(this).attr('data-group');
        if ($('div.checkbox.required[data-group="' + groupId + '"] :checkbox:checked').length > 0) {
            $('div.checkbox.required[data-group="' + groupId + '"] :checkbox').prop('required', false);
        }
        else {
            $('div.checkbox.required[data-group="' + groupId + '"] :checkbox').prop('required', true);
        }
    })
    $('input:radio').click(function () {
        var groupId = $(this).attr('data-group');
        if ($('div.radio.required[data-group="' + groupId + '"] :radio:checked').length > 0) {
            $('div.radio.required[data-group="' + groupId + '"] :radio').prop('required', false);
        }
        else {
            $('div.radio.required[data-group="' + groupId + '"] :radio').prop('required', true);
        }
    })

    $('.login-module__btn').click(function (e) {
        // e.preventDefault();
        $('.login-module').addClass('active');
    });

    $('.login-module__exit').click(function () {
        $('.login-module').removeClass('active');
    });

    $('.kit-request-btn').click(function () {
        console.log('click')
        $('#sample-modal').addClass('visible')
        if (window.location.pathname === '/z-cote' || window.location.pathname === '/z-cote-qr') {
            $("input[name='product']").val("FLSCC formulation kit")
        }
        else {
            $("input[name='product']").val("Sample Kit")
        }

    })

    $('.kit-request-btn').keypress(function () {
        $(this).click();
    })

    $('.close-modal').click(function () {
        $('#sample-modal').removeClass('visible')
    })

    $('.cmp-media__playbutton').click(function () {
        $('.cmp-media__embed-consent').css('display', 'flex')
        $('.cmp-media__playbutton').css('display', 'none')
    })

    $('.btn-accept').click(function () {
        $('.video-block__video-container').css('display', 'block')
        $('.video-block__content').css('display', 'block')
        $('.cmp-media__placeholder').css('display', 'none')
        $('.cmp-media__embed-consent').css('display', 'none')
    })

    $('.page-slider').slick({
        dots: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        infinite: true,
        arrows: true,
        speed: 1000,
        prevArrow: $('.slick-btn__prev'),
        nextArrow: $('.slick-btn__next'),
        autoplay: true,
        autoplaySpeed: 8000,
    });

    $('.cycle-slideshow').on('cycle-before', function (event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag) {
        var activeSlide = $(outgoingSlideEl);

        if (activeSlide.hasClass('video-slide')) {
            var video = document.getElementsByClassName("iframe-player");
            for (var i = 0; i < video.length; i++) {
                video.item(i).contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
            }
        }

    });

    var $theForm = $('#login-form');

    $theForm.submit(function () {
        var formData = $theForm.serialize() + '&action=users/saveUser';
        console.log(formData);
        $.ajax({
            context: this,
            type: 'POST', // define the type of HTTP verb we want to use (POST for our form)
            data: formData, // our data object
            dataType: 'json', // what type of data do we expect back from the server
            encode: true
        })
            // using the done promise callback
            .done(function (data) {
                // Once finished, submit the form as usual (straight to the pardot form handler)
                // 	this.submit();
            });

        return false;
    });

    $('.product-link').click(function () {
        var productName = $(this).attr('data-product-name')
        var productId = $(this).attr('data-product-id')

        window.dataLayer = window.dataLayer || [];

        dataLayer.push({
            'product_id': productId,
            'product_name': productName
        });
    })

    $('.video-modal__exit').click(function () {
        $('.video-modal').css('display', 'none');
        $('.yvideoplayer').each(function () {
            var el_src = $(this).attr("src");
            $(this).attr("src", el_src);
        });
        $(this).parent().find('video').trigger('pause');
    });

    $('.video-modal').click(function () {
        $(this).css('display', 'none');
        $('.yvideoplayer').each(function () {
            var el_src = $(this).attr("src");
            $(this).attr("src", el_src);
        });
        $(this).find('video').trigger('pause');
    }).on('click', '.video-modal__container', function (e) {
        e.stopPropagation();
    });

    $('.preview-feed__video-block[video-id]').click(function (e) {
        e.preventDefault;
        var videoId = $(this).attr('video-id');
        $('.video-modal[video-id=' + videoId + ']').css('display', 'block');
    });

    $('.tabs-row__tab').click(function () {
        console.log('tab click')
        var selectedCategory = $(this).val()
        var tabType = $(this).attr('data-tabtype')
        $('.tabs-row__tab').removeClass('active')
        $(this).addClass('active')
        console.log($(this))
        swapItems(selectedCategory, tabType)
    });
    
    $('.tabs-row__tab[tab-name]:not(.resource-tab)').click(function (e) {
        e.preventDefault();
        var tabName = $(this).attr('tab-name');
        console.log($(this))
        console.log('tabname', tabName);

        $('.tabs-row__tab').removeClass('active');
        $('.tabs-row__tab[tab-name=' + tabName + ']').addClass('active');

        $('.search-tab-content').removeClass('active');
        $('.search-tab-content[tab-name=' + tabName + ']').addClass('active');
    });

    $(document).ready(function () {
        // Show first tab's content
        $('.search-tab-content:first-child').addClass('active');
    });

    // var itemIndex = $('#filter-search').find('button[slug={{selectedBenefit}}]').index();
    // $('#filter-search').prop('selectedIndex', itemIndex).selectric('refresh');

    var optID = '';
    console.log('optID', optID);
    if (typeof optID !== 'undefined') {
        // swapItems(optID);
    }

// Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', function() {
        document.body.classList.add('using-mouse');
    });

// Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', function(event) {
        if (event.keyCode === 9) {
            document.body.classList.remove('using-mouse');
        }
    });



    // **************************************
    // Main Navigation Start

    var $mainNavigation = $('.main-navigation');

    // If an item with children is clicked
    $('.js-toggle-subnav > a').click(function(e){
        e.preventDefault();
        if ($(this).parent().is('.open')){
            closeSubnav($(this).parent().find('.subnav'));
        } else {
            openSubnav($(this).parent().find('.subnav'));
        }

    });

    function openSubnav(subnav){
        $('.subnav').hide();
        $('ul.level-0 li.open').removeClass('open');
        subnav.parent().addClass('open');
        subnav.slideDown('slow', function (){
        });
    }

    function closeSubnav(subnav){
        subnav.parent().removeClass('open');
        subnav.slideUp();
    }

    // Main Navigation End
    // **************************************


    // *************************************
    // Owl Carousel
    // 
    $(document).ready(function(){
        if($('.owl-carousel').length){
            $('.owl-carousel').owlCarousel({
                singleItem : true,
                itemsDesktop : false,
                itemsDesktopSmall : false,
                itemsTablet : false,
                itemsTabletSmall : false,
                itemsMobile : false,
                autoHeight : true,
                lazyLoad : false,
                addClassActive : true,
                afterMove : function() { playOnDrag(); }
            });        
        }
    });

    // Own Carousel End
    // *************************************


    // Mobile Navigation Start
    // **************************************
    var $mainNavigation = $('.main-navigation');

    // If an item with children is clicked
    $('.js-go-to-mobile-next-level > a').click(function (e) {
        e.preventDefault();
        nextMobileLevel($(this).parent().find('.subnav'));
    });

    $('.js-go-to-mobile-prev-level').click(function (e) {
        e.preventDefault();
        prevMobileLevel();
    });

    $('.js-toggle-mobile-nav').click(function () {
        $mainNavigation.toggleClass('mobile-nav-open');
        $mainNavigation.find('.mobile-navigation-wrapper').slideToggle();
    });


    function nextMobileLevel(subnav) {
        $mainNavigation.addClass('mobile-subnav-open');
        $('.subnav').removeClass('visible');
        subnav.addClass('visible');
    }

    function prevMobileLevel() {
        $mainNavigation.removeClass('mobile-subnav-open');
    }



    var activeSubnavName = '{% if craft.app.request.getSegment(1) is defined %}{{ craft.app.request.getSegment(1) }}{% endif %}';

    var activeSubnav = $('.subnav[subnav-id="' + activeSubnavName + ']');
    if (activeSubnav.parent().hasClass("has-subnav")) {
        nextMobileLevel(activeSubnav);
    }



    // Mobile Navigation End
    // **************************************


    // Checks on click targets. Closes certain menus dependant on target
    $(document).click(function(e) {
        e.stopPropagation();
        var target = e.target;
        //console.log(target);
        if (!$(target).is('.subnav') && !$(target).parents().is('.subnav') && !$(target).is('ul.level-0 li.open a')) {
            closeSubnav($('ul.level-0 li.open .subnav'));
        }

        if (!$(target).is('.js-open-search i') && !$(target).is('.js-open-search') && !$(target).is('.main-navigation__search') && !$(target).parents().is('.main-navigation__search')) {
            closeSearch();
        }

        if (!$(target).is('.js-open-share i') && !$(target).is('.js-open-share') && !$(target).is('.share-menu') && !$(target).parents().is('.share-menu')) {
            closeShare();
        }
    });



    // **************************************
    // Search Start


    $('.js-open-search').click(function(e){
        e.preventDefault();
        openSearch();
    });

    $('.js-open-search').on('keydown', function(e){
        if (e.which === 13){
            e.preventDefault();
            openSearch('keyboard');
        }
    });

    $('.js-close-search').click(function(e){
        e.preventDefault();
        closeSearch();
        $('.js-open-search').focus();
    });

    $('.close-search-bar').on('keydown', function(e){
        if (e.which === 13){
            closeSearch();
            $('.js-open-search').focus();
        }
    });

    function openSearch(inputDevice){
        $mainNavigation.addClass('is-searching');
        if (inputDevice === 'keyboard'){
            $( "#close-search" ).focus();
        }
        else {
            $( "#search" ).focus();
        }
        
        closeShare();
    }

    function closeSearch(){
        $mainNavigation.removeClass('is-searching');
    }

    // Search End
    // **************************************



    $('.js-open-share').click(function(e){
        e.preventDefault();
        openShare();
    });

    $('.js-open-share').on('keydown', function(e){
        if (e.which === 13){
            e.preventDefault();
            openShare();
        }
    });

    $('.js-close-share').click(function(e){
        closeShare();
    });

    $('.js-close-share').on('keydown', function(e){
        if (e.which === 13){
            closeShare();
            document.querySelector('.js-open-share').focus();
        }
    });

    function openShare(){
        $mainNavigation.addClass('is-sharing');
        document.querySelector('.js-close-share').focus();
    }

    function closeShare(){
        $mainNavigation.removeClass('is-sharing');  
    }



    // filter accordion accessibility
    $(".filters-block input").on('keydown', function(e){
        if (e.key === 'Enter') {
            e.preventDefault();
            var checkStatus = $(this).prop('checked');
            $(this).prop('checked', !checkStatus);
        }
    })
    

    // localizations
    $('.js-open-locale').click(function(e){
        e.preventDefault();
        $(this).toggleClass('js-close-locale');
        $('.localizations').toggleClass('is-locale');
    });


    var scrollThrottleTimeout;
    $(window).bind(
        'scroll',
        function()
        {
            // if (!scrollThrottleTimeout) {
            //     scrollThrottleTimeout = setTimeout(
            //         function()
            //         {
            //             //  console.log('test');
                        onPageScroll();
                        //scrollThrottleTimeout = null;
            //         },
            //         100
            //     );
            // }
        }
    );


    function onPageScroll(){
        // console.log("Scrolled");
        if ($(window).scrollTop() >= 80) {
            $('.main-navigation').addClass('is-fixed');
            $('.fancy-nav__bg').addClass('is-fixed');
            $('.social-share-widget').addClass('scrolled');
        } else {
            $('.main-navigation').removeClass('is-fixed');
            $('.fancy-nav__bg').removeClass('is-fixed');
            $('.social-share-widget').removeClass('scrolled');

        }
    }


    // Select box
    $("select.minimalect").minimalect({
        placeholder: null,
        searchable: false,
        class_active: 'active',
        class_disabled: 'disabled',
        empty: ""
    });
    $('.accordion-title').click(function(e) {
        e.preventDefault();
        $(this).toggleClass('show');
        var $this = $(this);

        if ($this.next().hasClass('show')) {
            $this.next().removeClass('show');
            $this.next().slideUp(0);
        } else {
            $this.parent().parent().find('.accordion-row .accordion-content').removeClass('show');
            $this.parent().parent().find('.accordion-ro .accordion-content').slideUp(0);
            $this.next().toggleClass('show');
            $this.next().slideToggle(0);
        }
    });


    // IE Polyfill for Object Fit
    if ( ! Modernizr.objectfit ) {
        $('.object-fit-img').each(function () {
            var $container = $(this).parent(),
                imgUrl = $(this).prop('src');
            if (imgUrl) {
                $container
                    .css('backgroundImage', 'url(' + imgUrl + ')')
                    .addClass('compat-object-fit');
            }
        });
    }



    // Fancy Nav
    $(document).on('click', '.js-open-fancy-nav', function(e){
        e.preventDefault();
        openFancyNav($(this));

        // $(window).scroll(function() {
        //     console.log($(window).scrollTop());

        //     var testing = '';
        //     if($(window).scrollTop() == 0){
        //         alert('at top');
        //         var testing = 'testing';
        //     }

        //     if($(window).scrollTop() >100) {

        //             alert('at 100!');
        //             // $('.js-open-fancy-nav').removeClass('nav-open');
        //             // $('.fancy-nav__bg').removeClass('active');
        //     }
        // });
    });

    $('.fancy-nav__close').click(function(e){
        e.preventDefault();
        closeFancyNav();
    });



    function openFancyNav(navItem){
        //scroll to the top

       // $('html, body').animate({scrollTop:0}, 700);       

        if (navItem.hasClass('fancy-nav-benefits')){
            $('.fancy-nav__benefits').toggleClass('active');
            $('.fancy-nav__trends').removeClass('active');
            $('.fancy-nav-trends').attr('aria-expanded', 'false');

            navItem.toggleClass('nav-open');
            $('.fancy-nav-trends').removeClass('nav-open');
        }

        if (navItem.hasClass('fancy-nav-trends')){
            $('.fancy-nav__trends').toggleClass('active');
            $('.fancy-nav__benefits').removeClass('active');
            $('.fancy-nav-trends').attr('aria-expanded', 'true');

            navItem.toggleClass('nav-open');
            $('.fancy-nav-benefits').removeClass('nav-open');
        }

        $('.fancy-nav__close').focus();


        //keyboard cycle focus on level 2
        var cycle2__firstTabItem = $('.fancy-nav__close');
        var cycle2__finalTabItem = $('.fancy-nav__second-level li:last-of-type a'); 

        $(cycle2__firstTabItem).on('keydown', function(e){
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    e.preventDefault();
                    cycle2__finalTabItem.focus();
                 }
            }
        })

        $(cycle2__finalTabItem).on('keydown', function(e){
            if (e.key === 'Tab') {
                if (!e.shiftKey) {
                    e.preventDefault();
                    cycle2__firstTabItem.focus(); 
                }
            }
        })
    }

    function closeFancyNav(){
        $('.js-open-fancy-nav').removeClass('nav-open');
        $('.fancy-nav__bg').removeClass('active');
        $('.fancy-nav-trends').attr('aria-expanded', 'false');
        $('#trends a').focus();
    }



    $('.fancy-nav__second-level-link[category-tab-name]').click(function(e){
        e.preventDefault();

        var navContainer = $(this).closest('.fancy-nav');


        navContainer.find('.fancy-nav__second-level-link').removeClass('active');
        $(this).toggleClass('active');

     

        var categoryTabName = $(this).attr('category-tab-name');

        navContainer.find('.fancy-nav__third-level').removeClass('active');
        navContainer.find('.fancy-nav__third-level[category-tab-name=' + categoryTabName + ']').addClass('active');

        navContainer.find('.fancy-nav__img').removeClass('active');
        navContainer.find('.fancy-nav__img[category-tab-name=' + categoryTabName + ']').addClass('active');

        //back button
        var level3BackBtn = $('.fancy-nav__third-level[category-tab-name=' + categoryTabName + '] .fancy-nav__back-to-second-level');
        level3BackBtn.focus();
        $(level3BackBtn).on('click', function(e){
            e.preventDefault();
            $('.fancy-nav__second-level-link[category-tab-name=' + categoryTabName + ']').focus();
        })

        //keyboard cycle focus on level 3
        var cycle3__firstTabItem = level3BackBtn;
        var cycle3__finalTabItem = $('.fancy-nav__third-level[category-tab-name=' + categoryTabName + '] .column:last-of-type div:last-of-type a');
        
        $(cycle3__firstTabItem).on('keydown', function(e){
            if (e.key === 'Tab') {
                if (e.shiftKey) {
                    e.preventDefault();
                    cycle3__finalTabItem.focus();
                 }
            }
        })

        $(cycle3__finalTabItem).on('keydown', function(e){
            if (e.key === 'Tab') {
                if (!e.shiftKey) {
                    e.preventDefault();
                    cycle3__firstTabItem.focus(); 
                }
            }
        })
    });


  

    //copy to clipboard link in nav
    var currentUrl = window.location.href,
        urlInput = document.createElement('input');

    $('.copyUrl').click(function(){
        document.body.appendChild(urlInput);
        urlInput.value = currentUrl;
        urlInput.select();
        document.execCommand('copy');
        document.body.removeChild(urlInput);
        $('.copy-url-success').fadeIn();

        setTimeout(function() {
          $('.copy-url-success').fadeOut();
        }, 2000);
    });




    //videos in resources
    
    // $('.video-modal__exit').click(function(){
    //     $('.video-modal').css('display', 'none');
    //     // $('iframe').get(0).stopVideo();
    // });

    // $('.video-modal').click(function(){
    //     $(this).css('display', 'none');
    // });
    var pathName = window.location.pathname;
    if (pathName.indexOf('resources/videos/watch') > -1){
        $('.preview-feed__video-block[video-id]').click(function(e){
            e.preventDefault;
            $('html, body').animate({scrollTop:0}, 700);
            var videoId = $(this).attr('video-id');
            $('.video-modal[video-id=' + videoId +']').css('display', 'block');
        });
    }
    




    // hide social share icons on scroll when page gets to 500px from bottom 

    document.onscroll = function() {

        var currentScroll = window.innerHeight + window.scrollY,
            pageHeight = document.body.clientHeight - 500;

        if (pageHeight < currentScroll){
            $('.social-share-widget').fadeOut();
        } else {
            $('.social-share-widget').fadeIn();
        }

    }


    //throttle function
    function throttle(fn, wait) {
        var time = Date.now();
        return function() {
            if ((time + wait - Date.now()) < 0) {
                fn();
                time = Date.now();
            }
        }
    }

    // visual element array
    var $animationElements = $('.js-element-in-view');

    //create a reusable function to track elements placment on screen and run anmiations
    function elementInView() {

        var topOfScreen = $(window).scrollTop();
        var bottomOfScreen = topOfScreen + $(window).innerHeight();

        $.each($animationElements, function(index, element){
            var element = $(element);
                elementTop = element.offset().top;
                elementBottom = elementTop + element.outerHeight();

            if ((bottomOfScreen > elementTop) && (topOfScreen < elementBottom)){
                element.addClass('animate');
            } else if (!element.hasClass('js-animate-once')) {
                element.removeClass('animate');
            }

            if (element.hasClass('stat-block__number')) {
                var percent = element.attr('stat-percent');
                // console.log(percent);

                // animateValue(element, percent, 0, 1500);
            }
        });
    }

    window.addEventListener('scroll', throttle(elementInView, 200));
    elementInView();



    // create a function to track the stroy nav and hvae it stay in one place
    function stickStoryNav() {

        var topOfScreen = $(window).scrollTop();
        var stickyNav = $('.js--interactive-nav');
            if(stickyNav.length != 0){
                // console.log(stickyNav);
                stickyNavTop = stickyNav.offset().top;
                stickyNavBottom = stickyNavTop + stickyNav.outerHeight();
            }
            
            var stickyNavContainer = $('.js--interactive-story__nav'); 
            if(stickyNavContainer.length != 0){
              stickyNavContainerBottom = stickyNavContainer.offset().top + stickyNavContainer.outerHeight();  
            }
            
        let hasFullCoverHeader = document.querySelector('.js-landing-header-cover');
        let coverHeight = 890;
        if (hasFullCoverHeader) {
            coverHeight = hasFullCoverHeader.offsetHeight;
        }
        // if ((topOfScreen > 890) && (stickyNavBottom < stickyNavContainerBottom)) {
        if(stickyNavContainer.length != 0 && stickyNav.length != 0)
            if (hasFullCoverHeader && (topOfScreen > coverHeight) && (stickyNavBottom < stickyNavContainerBottom)){
                stickyNav.addClass('is-fixed');
            }
            else if (!hasFullCoverHeader && (topOfScreen > 890) && (stickyNavBottom < stickyNavContainerBottom)) {
                stickyNav.addClass('is-fixed');
            } else if (stickyNavBottom > stickyNavContainerBottom) {
                stickyNav.removeClass('is-fixed');
            } else {
                stickyNav.removeClass('is-fixed');
            }
    }

    window.addEventListener('scroll', throttle(stickStoryNav, 200));
    stickStoryNav();

    // get story side nav links
    var $storySideLinkAnchors = $('.js--story-nav-anchor');

    //create a function to track the story content and highlight the correct story nav link when in that section
    function storyNavLinkHighlight(){

        var topOfScreen = $(window).scrollTop();
        var bottomOfScreen = topOfScreen + $(window).innerHeight();

        $.each($storySideLinkAnchors, function(index, element){
            var element = $(element);
            var elementTop = element.offset().top;
            var elementId = element.attr('id');``
            var elementBottom = elementTop + element.outerHeight();
                // console.log({elementBottom})

            if ((bottomOfScreen > elementTop) && (topOfScreen < elementBottom)){
                $('.js--interactive-nav__link').attr('data-selected', '');
                $('.js--interactive-nav__link[section-id="' + elementId + '"]').attr('data-selected', 'true');
            }
        });
    }

    window.addEventListener('scroll', throttle(storyNavLinkHighlight, 200));
    storyNavLinkHighlight();


    // ========================
    // ========================
    // fancybox settings 
    $('[data-fancybox="gallery"]').fancybox({
        keyboard: true,
        preventCaptionOverlap: false, 
        infobar: false,
        buttons: [
            "slideShow",
            "thumbs",
            "close"
        ],
        idleTime: 300,
    });

    // hide bar charts within popover after 0.02s to allow the height and width to display for the charts to work.
    window.setTimeout(function(){
        $('.js--bar-chart__popover').each(function(){
            $(this).hide();
        });
    }, 20);


    // ===============
    // interactive side nav scroll
    $('.js--interactive-nav__link').click(function(e){
        e.preventDefault();
        var sectionId = $(this).attr('section-id');

        $('.js--interactive-nav__link').attr('data-selected', '');
        $(this).attr('data-selected', 'true');

        // console.log(sectionId);

        $('html, body').animate({
            scrollTop: ($('#' + sectionId).offset().top - 100)
        }, 2000);
    });

    // Initialize Selectric and bind to 'change' event
    $('#filter-search').on('change', function () {
        var thisSelect = $(this);
        swapItems(thisSelect.val());

    });

    var listEl = document.getElementById('sortable-list-{{ block.id }}');
    // var sortable = new Sortable(listEl, {
    //     group: "name",  // or { name: "...", pull: [true, false, 'clone', array], put: [true, false, array] }
    //     sort: true,  // sorting inside list
    //     delayOnTouchOnly: false, // only delay if user is using touch
    //     disabled: false, // Disables the sortable if set to true.
    //     handle: ".drag-icon",  // Drag handle selector within list items
    //     draggable: ".sortable-list__item",  // Specifies which items inside the element should be draggable

    //     // Element is chosen
    //     // onChoose: function (/**Event*/evt) {
    //     // 	evt.oldIndex;  // element index within parent
    //     // 	console.log(evt.oldIndex);
    //     // },

    //     // // Element dragging ended
    //     // onEnd: function (/**Event*/evt) {
    //     // 	var itemEl = evt.item;  // dragged HTMLElement
    //     // 	evt.to;    // target list
    //     // 	evt.from;  // previous list
    //     // 	evt.oldIndex;  // element's old index within old parent
    //     // 	evt.newIndex;  // element's new index within new parent
    //     // 	evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
    //     // 	evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
    //     // 	evt.clone // the clone element
    //     // 	evt.pullMode;  // when item is in another sortable: `"clone"` if cloning, `true` if moving
    //     // 	console.log(evt.newIndex);
    //     // },
    // });


});



//Accessibility :: go through and add aria-labels to <a> that take off page and are missing
$(document).ready(function() {
    $('main a[target="_blank"]').each(function(){
        if ($(this).attr("aria-label") === undefined) {
            var textContent = $(this).text();
            var ariaNewLabel = "New tab opens to " + textContent;
            $(this).attr("aria-label", ariaNewLabel);
        }
    })
});

// Disclaimer Cookies. These cookies use the GDPR Cookies script file, so it must be loaded before this file within gulp

function acceptNACookiePrompt(){
    setCookie('NADisclaimer','accepted',180);
    document.getElementById("na-disclaimer").style.display = "none";
}

$('.na-exit').on("keydown", function(e){
    if (e.key === 13){
        acceptNACookiePrompt();
    }
})


window.onload = function(){
    var gdprCookie = getCookie('GDPRCookiePrompt');
    var naCookie = getCookie('NADisclaimer');
    if (!gdprCookie) {
        showGdprPopup();
    }
    if (naCookie) {
        document.getElementById("na-disclaimer").style.display = "none";
    }
};



function autoLogin() {
    fetch('/actions/motum-saml-module/default/landing-page-auth')
        .then(function (response) {
            // console.log('res', response)
            return response;
        })
        .then(function (myJson) {
            // console.log('json', myJson)
            location.reload();
            // document.getElementById('registration-form').submit()
        });
}

function generateHash(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

$(".filters-block .category-title").on('click', function (e) {
    console.log('click accordion')
    var expandedStatus = $(this).attr("aria-expanded");
    expandedStatus = (expandedStatus === "true") ? "false" : "true";
    $(this).attr("aria-expanded", expandedStatus);
})

$('.btn--submit').click(async function (e) {
    try {
        const response = await fetch('/actions/motum-saml-module/default/get-blocklist')
        const blockList = await response.json()
        const emailInput = document.getElementById("landingPageRegistration").elements["email"]
        const domain = emailInput.value.split('@')[1]
        if (!blockList.includes(domain)) {
            // autoLogin()
            localStorage.removeItem('hash');
            const hashInput = document.getElementById("hash")
            const hash = generateHash(10)
            hashInput.value = hash
            localStorage.setItem('hash', hash);
            document.getElementById('landingPageRegistration').submit()
        } else {
            $('#invalid-email').html('This email address is not valid. Please try again.')
        }
    } catch (error) {
        console.error(error)
    }
})

$(document).ready(function () {
    if (window.location.search.indexOf('?h=') > -1) {
        const urlHash = new URL(location.href).searchParams.get('h')
        const storedHash = localStorage.getItem('hash')
        if (urlHash === storedHash) {
            autoLogin()
        }
    }
})

$('.bio-block-link').click(function () {
    var name = $(this).parent().find('h2.bio-block__title')[0].innerText

    var iframeForm = $('iframe#get-in-touch')
    if (iframeForm && iframeForm.length > 0) {
        var message = 'I would like to get in touch with ' + name
        var messageObj = { type: 'bio-block', message: message }
        iframeForm.get(0).contentWindow.postMessage(messageObj, "https://cloud.care-chemicals.basf.com");
        //$(textArea).val('I would like to get in touch with ' + name)
    } else {
        $("textarea[name='message']").val('I would like to get in touch with ' + name)
    }

})

var contactLinkTitles = [];
$('.image-link-block__link').click(function () {
    var title = $(this).attr('title')

    if (contactLinkTitles.includes(title)) {
        contactLinkTitles = contactLinkTitles.filter(a => a !== title);
    } else {
        contactLinkTitles.push(title)
    }
    var message = contactLinkTitles.length > 0 ? "I would like to receive information about " : "";
    for (i = 0; i < contactLinkTitles.length; i++) {
        if (i == 0) {
            message += contactLinkTitles[i]
        } else {
            message += " and " + contactLinkTitles[i]
        }
    }
    $("textarea[name='message']").val(message)
})

$('input[name="url"]').val(window.location)

$('.sample-request-btn').click(function () {
    var product = $(this).parent().parent().find('.preview-feed__product-block').find('h3.content-block__title')[0].innerText
    console.log('product', product)
    $('#sample-modal').addClass('visible')
    $("input[name='product']").val(product)
})

$('[href="#open-form-modal"]').click(function (e) {
    e.preventDefault();
    $('#sample-modal').addClass('visible')
})



$('.close-modal').click(function () {
    $('#sample-modal').removeClass('visible')
    $('#thankyou-modal').removeClass('visible')
})

if (location.search.indexOf('thank-you') > -1) {
    $('#thankyou-modal').addClass('visible')
}

$('.sample-request-btn').keypress(function () {
    $(this).click();
})

$(document).on('keydown', function (e) {
    if (e.which == 27) {
        if ($('#sample-modal').hasClass('visible')) {
            $('#sample-modal').removeClass('visible')
        }

        if ($('#form-modal').hasClass('visible')) {
            $('#form-modal').removeClass('visible')
        }

        if ($('#thankyou-modal').hasClass('visible')) {
            $('#thankyou-modal').removeClass('visible')
        }
    }
});


// Accordion
$('.accordion-block__preview[aria-controls]').click(function () {
    var ariaControlsId = $(this).attr('aria-controls');
    var ariaExpanded = $(this).attr('aria-expanded');
    console.log(ariaExpanded);

    if (ariaExpanded == 'false') {
        $(this).attr("aria-expanded", "true");
    } else {
        $(this).attr("aria-expanded", "false");
    }

});

$('.category-title[category-title]').click(function (e) {
    e.preventDefault();
    var selectedCat = $(this).attr('category-title');
    $(this).toggleClass('open');
    $('.form-group__sub-fields[category-title=' + selectedCat + ']').slideToggle();
});

$('#filter-search').click(function (e) {
    var tabType = $(this).attr('data-tabtype');
    console.log('tabType', tabType);

    var ids = [];

    $(".checkbox-input").each(function (index) {
        if ($(this).is(':checked')) {
            ids.push($(this).attr('value'));
        }
    });
    if (ids.length !== null) {
        console.log('ids', ids);
        $.when($('#query-container').fadeOut()).then(function () {
            $.when($('#query-container').empty()).then(function () {
                $('#query-container').append('<div class="filters-loading" aria-hidden=”true”><span></span><span></span><span></span></div>').fadeIn();
                ajaxCall(ids, tabType);
            })

        })

    }
});

$('#filter-clear').click(function (e) {

    var ids = [];

    $(".checkbox-input").each(function (index) {
        $(this).prop('checked', false);
    });
    $.when($('#query-container').fadeOut()).then(function () {
        $.when($('#query-container').empty()).then(function () {
            $('#query-container').append('<div class="filters-loading" aria-hidden=”true”><span></span><span></span><span></span></div>').fadeIn();
            ajaxCall(0);
        })

    })
});

function ajaxCall(ids, tabType = '') {
    if (tabType) {
        console.log('tabType', tabType);
        console.log('/filter-index/?type='+tabType+'&ids=' + ids);
        $.ajax('/filter-index/?type='+tabType+'&ids=' + ids, {
            dataType: 'html',
            success: function (response) {
                refreshProjects(response);
            }
        });
    }
}

function swapItems(optID, tabType = '') {
    console.log("swapItems", optID);
    $.when($('#query-container').fadeOut()).then(function () {
        $.when($('#query-container').empty()).then(function () {
            $('#query-container').append('<div class="filters-loading" aria-hidden=”true”><span></span><span></span><span></span></div>').fadeIn();
            ajaxCall(optID, tabType);
        });
    });
}


//    $('#filter-search').click(function(e){
//        // $('#filter-search').trigger('render');
//        var ids = [];
//
//        ids.push($(this).find(":selected").val());
//        console.log(ids);
//        if(ids.length !== null){
//
//
//        }
//    });


function refreshProjects(html) {

    var $html = $('<div />').append(html);
    // console.log($html.find('#query-container').html().length);
    $.when($('#query-container').fadeOut()).then(function () {
        $.when($('#query-container').empty()).then(function () {
            $('#query-container').append($html.find('#query-container').html()).fadeIn();
        })
    })

}


$('.btn--submit').click(async function (e) {
    try {
        const response = await fetch('/actions/motum-saml-module/default/get-blocklist')
        const blockList = await response.json()
        const emailInput = document.getElementById("registration-form").elements["email"]
        const domain = emailInput.value.split('@')[1]
        if (!blockList.includes(domain)) {
            // autoLogin()
            localStorage.removeItem('hash');
            const hashInput = document.getElementById("hash")
            const hash = generateHash(10)
            hashInput.value = hash
            localStorage.setItem('hash', hash);
            document.getElementById('registration-form').submit()
        } else {
            $('#invalid-email').html('This email address is not valid. Please try again.')
        }
    } catch (error) {
        console.error(error)
    }
})

$(document).ready(function () {
    if (window.location.search.indexOf('?h=') > -1) {
        const urlHash = new URL(location.href).searchParams.get('h')
        const storedHash = localStorage.getItem('hash')
        if (urlHash === storedHash) {
            autoLogin()
        }
    }
})



$('.js--quiz-btn').click(function (e) {
    e.preventDefault();
    var quizId = $(this).attr('quiz-id');

    $('#sortable-list-' + quizId).find('.sortable-list__item').each(function () {
        $(this).find('.drag-icon').hide();
        $(this).find('.user-placement').show();
        $(this).find('.correct-placement').show();

        var userPlacement = $(this).index();
        var placement = userPlacement + 1;
        var prettyPlacement = placement;

        if (placement < 10) {
            var prettyPlacement = '0' + placement;
        }

        $(this).find('.user-placement').html(prettyPlacement);

        // var correctPlacement = $(this).find('.correct-placement').html();
        var correctPlacement = $(this).attr('data-sort');

        if (placement == correctPlacement) {
            $(this).addClass('correct');
        } else {
            $(this).addClass('incorrect');
        }

    });

    setTimeout(function () {
        $('#sortable-list-' + quizId).find('.sortable-list__item').sort(function (a, b) {
            return +a.dataset.sort - +b.dataset.sort;
        }).appendTo($('#sortable-list-' + quizId));
    }, 200);

    $(this).attr('disabled', 'true');
    $('.js--quiz-result-copy[quiz-results="' + quizId + '"]').slideDown();
});

var sliderActive;
$("#juxtapose-slider").on('mousedown', function () {
    clearInterval(sliderActive);
    sliderActive = setInterval(function () {
        swapSlideText(slider.sliderPosition);
    }, 200);
});
$("#juxtapose-slider").on('touchstart ', function () {
    clearInterval(sliderActive);
    sliderActive = setInterval(function () {
        swapSlideText(slider.sliderPosition);
    }, 200);
});
$("#juxtapose-slider").on('keydown ', function () {
    clearInterval(sliderActive);
    sliderActive = setInterval(function () {
        swapSlideText(slider.sliderPosition);
    }, 200);
});
$("#juxtapose-slider").click(function () {
    clearInterval(sliderActive);
    swapSlideText(slider.sliderPosition)
});
$("#juxtapose-slider").on('touchend', function () {
    clearInterval(sliderActive);
    swapSlideText(slider.sliderPosition)
});
function swapSlideText(position) {
    var percentage = parseInt(position, 10);
    console.log(percentage)
    if (percentage < 50) {
        $('.slider-text-container.left-slide').removeClass('active');
        $('.slider-text-container.right-slide').addClass('active');
        $('.slider-prompt').fadeOut();
    }
    else {
        $('.slider-text-container.left-slide').addClass('active');
        $('.slider-text-container.right-slide').removeClass('active');
    }
}

const preparingDownloadForm = document.getElementById('preparingDownloadForm');
if (preparingDownloadForm !== null) {
    preparingDownloadForm.addEventListener('freeform-ready', function (event) {
        const freeform = event.target.freeform;
        freeform.addOnSuccessfulAjaxSubmit((event, preparingDownloadForm, response) => {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'gatedResourceDownload',
                'resourceName': '{{resource.title}}',
            });
            $('#download-file').submit();
            if (response.finished) {
                window.location.href = response.returnUrl;
            }
        })
    });

    preparingDownloadForm.addEventListener('submit', function () {
        $('#query-container').append('<div class="filters-loading" aria-hidden=”true”><span></span><span></span><span></span></div>').fadeIn();
    });
}

var $theForm = $('#register-form');
if ($theForm !== null) {
    $theForm.submit(function () {
        var formData = $theForm.serialize();
        var finalFormData = $theForm.serialize() + '&username=' + makeid() + '&firstName=' + $('[name=firstname]').val() + '&lastName=' + $('[name=lastname]').val() + '&action=users/save-user';
        // console.log(finalFormData);
        $.ajax({
            context: this,
            type: 'POST', // define the type of HTTP verb we want to use (POST for our form)
            data: finalFormData, // our data object
            dataType: 'json', // what type of data do we expect back from the server
            encode: true
        }).done(function (data) {
            console.log(data);
            // Once finished, submit the form as usual (straight to the pardot form handler)
            if (data.errors) {
                alert(data.errors.email);
                return false;
            } else {
                this.submit();
            }
        });
        return false;
    });
}

function makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return '0-' + text;
}

const newsletterForm = document.getElementById('newsletterSignUp');
if (newsletterForm !== null) {
    const countryInput = document.getElementById('form-input-countryRegion');
    const submitButton = document.getElementById('submit');
    const approvedCountries = ['US', 'CA', 'MX', 'GT', 'HN', 'NI', 'SV', 'CR', 'PA', 'BZ', 'AG', 'BS', 'BB', 'CU', 'DM', 'DO', 'GD', 'HT', 'JM', 'KN', 'LC', 'VC', 'TT', 'PR'];
    newsletterForm.addEventListener("freeform-on-submit", function (submitEvent) {
        console.log('country', countryInput.value);
        if (approvedCountries.indexOf(countryInput.value) < 0) {
            alert('You must reside in United States, Canada, Mexico, Guatemala, Honduras, Nicaragua, El Salvador, Costa Rica, Panama, Belize, Antigua And Barbuda, The Bahamas, Barbados, Cuba, Dominica, Dominican Republic, Grenada, Haiti, Jamaica, St. Kitts and Nevis, St. Lucia, St. Vincent, Grenadines, Trinidad, Tobago, Puerto Rico to join the newsletter.');
            submitEvent.preventDefault();
        }
    });
}

const urlInput = document.getElementById('form-input-formUrl');
if (urlInput !== null) {
    urlInput.value = window.location.href;
}